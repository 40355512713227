import React from "react"
import { useStaticQuery, graphql } from "gatsby"

function compareByLatestDate(a, b) {
  if (a.Date < b.Date) {
    return 1;
  }
  if (a.Date > b.Date) {
    return -1;
  }
  return 0;
}

const NewsFlex = props => {
  const data = useStaticQuery(graphql`
    query {
      allStrapiTalk {
        nodes {
          Slug
          Title
          Author
          Excerpt
          Background { localFile { url } }
          Thumbnail { localFile { url } }
          Highlight
          Date
        }
      }
    }
  `)
  const articles = data.allStrapiTalk.nodes.sort(compareByLatestDate).slice(0, 4);

  return <div className={props.white ? 'content-pad white-bg' : 'content-pad'}>
    <h2>talk</h2>

    <div className="news-flex">
      {articles.map(a =>
        <a href={'/'+a.Slug} className={a.Highlight ? 'news-block highlight' : 'news-block'}
          style={a.Background && {
            backgroundImage: `url("${a.Background.localFile.url}")`, color: 'white',
          }}
        >
          {a.Thumbnail && <img src={a.Thumbnail.localFile.url}/>}
          <b>{a.Title}</b>
          {!a.Thumbnail && <span className="small-text">{a.Author}</span>}
          {a.Excerpt && !a.Thumbnail && !a.Highlight && <span>{a.Excerpt}</span>}
        </a>
      )}
    </div>

    <div id="otherNews">
      <a href="/talk/">Leggi tutti gli articoli</a>
    </div>
  </div>
}

export default NewsFlex
