import React from "react"

import bambini from "../images/bambini.png"

class Animation extends React.Component {
  constructor(props) {
    super(props)
  }

  componentDidMount() {
    const animation = document.getElementById("animation");
    let overAnimation = false;
    if (window.innerWidth > 800) { // desktop
      animation.onmouseover = () => overAnimation = true;
      animation.onmouseout = () => overAnimation = false;
    } else { // mobile
      animation.onclick = () => overAnimation = !overAnimation;
    }

    const bambiniImage = document.getElementById("bambiniImage");
    const titlesSlides = document.getElementById("titlesSlides");
    const textSlides = document.getElementById("textSlides");
    function doAnimationFrame(n) {
      if (overAnimation) {
        // delay frame
        setTimeout(() => doAnimationFrame(n), 1000);
        return
      }
      bambiniImage.style.left = -1280*n + "px";
      titlesSlides.style.top = textSlides.style.top = -150*n + "%";
      titlesSlides.style.opacity = textSlides.style.opacity = 0;
      setTimeout(() => titlesSlides.style.opacity = textSlides.style.opacity = 1, 1500);
      if (n < 5) {
        setTimeout(() => doAnimationFrame(n+1), 5000);
        return;
      }
      // n === 5, animation is looping, reset state to frame 0:
      setTimeout(() => {
        bambiniImage.style.transitionDuration = "0s";
        titlesSlides.style.transitionDuration = "0s";
        textSlides.style.transitionDuration = "0s";
      }, 3000);
      setTimeout(() => {
        bambiniImage.style.left = "0";
        titlesSlides.style.top = textSlides.style.top = "0";
      }, 3500);
      setTimeout(() => {
        bambiniImage.style.transitionDuration = null;
        titlesSlides.style.transitionDuration = null;
        textSlides.style.transitionDuration = null;
      }, 4000);
      setTimeout(() => doAnimationFrame(1), 5000);
    }
    setTimeout(() => doAnimationFrame(1), 3000);
  }

  render = () => (
    <div id="animation">
      <div id="bambini">
        <div id="bambiniContainer">
          <img id="bambiniImage" src={bambini}/>
        </div>
        <div id="bambiniMask"></div>
      </div>

      <div id="ideas">
        <div id="textSpacing" className="content-pad">
          <span className="care">spacing</span>
          <h3>spacing</h3>
          <p>
            <span className="uppercase">istruzione, formazione, inclusione, disseminazione</span><br/>
            L'educazione e l'istruzione sono parte fondamentale del progetto della Fondazione che pertanto finanzierà
            l'attività di giovani ricercatori e sosterrà la formazione nel campo sanitario. La Commissione Scientifica
            di Silene individuerà e promuoverà collaborazioni per il superamento di barriere e frontiere puntando alla
            diffusione della conoscenza e della ricerca.
          </p>
        </div>

        <div id="titlesSlides">
          <div className="content-pad">
            <h1>Ideas for <span className="care">Care</span></h1>
            <p>
              La Fondazione Silene Ideas for CARE sostiene l'innovazione attraverso la realizzazione di nuovi modelli
              di assistenza e cura che si integrano con attività e progetti medico assistenziali a supporto
              di aree geografiche di differente complessità economico-sociale. La mission della Fondazione consiste
              nella programmazione di attività che, integrando gli aspetti educazionale e assistenziale,
              favoriscano la realizzazione di progetti duraturi e integrati nel territorio. Silene incardina le sue idee
              e i suoi progetti su quattro pilastri: <b>Collaborazione, Assistenza, Ricerca e Educazione</b>.
            </p>
          </div>
          <div className="content-pad">
            <span className="care">cooperation</span>
            <h3>collaborazione</h3>
            <p>
              <span className="uppercase">attività, competenze, networking</span><br/>
              La Fondazione Silene Ideas for CARE promuove collaborazioni a sostegno della salute attraverso la
              creazione di network internazionali. Utilizza tutte le forme di comunicazione, compresa quella artistica,
              a supporto della ricerca.
            </p>
          </div>
          <div className="content-pad">
            <span className="care">assistance</span>
            <h3>assistenza</h3>
            <p>
              <span className="uppercase">aiuto, sostegno, ambiente</span><br/>
              Tra gli obiettivi principali la Fondazione ha quello di portare aiuto in vari ambiti, da quello
              medico-scientifico a quello assistenziale, da quello informativo a quello formativo, fino alla
              realizzazione di progetti che sostengano il benessere psicofisico della persona.
            </p>
          </div>
          <div className="content-pad">
            <span className="care">research</span>
            <h3>ricerca</h3>
            <p>
              <span className="uppercase">studio, cura, progresso</span><br/>
              La Fondazione vuole attivare programmi di ricerca a supporto della cura e dell'assistenza sia in realtà
              ad elevato tenore socio economico che in aree meno privilegiate
            </p>
          </div>
          <div className="content-pad">
            <span className="care">education</span>
            <h3>educazione</h3>
            <p>
              <span className="uppercase">istruzione, formazione, inclusione, disseminazione</span><br/>
              L'educazione e l'istruzione sono parte fondamentale del progetto della Fondazione che pertanto finanzierà
              l'attività di giovani ricercatori e sosterrà la formazione nel campo sanitario. La Commissione Scientifica
              di Silene individuerà e promuoverà collaborazioni per il superamento di barriere e frontiere puntando alla
              diffusione della conoscenza e della ricerca.
            </p>
          </div>
          <div className="content-pad">
            <h1>Ideas for <span className="care">Care</span></h1>
            <p>
              La Fondazione Silene Ideas for CARE sostiene l'innovazione attraverso la realizzazione di nuovi modelli
              di assistenza e cura che si integrano con attività e progetti medico assistenziali a supporto
              di aree geografiche di differente complessità economico-sociale. La mission della Fondazione consiste
              nella programmazione di attività che, integrando gli aspetti educazionale e assistenziale,
              favoriscano la realizzazione di progetti duraturi e integrati nel territorio. Silene incardina le sue idee
              e i suoi progetti su quattro pilastri: <b>Collaborazione, Assistenza, Ricerca e Educazione</b>.
            </p>
          </div>
        </div>

        <div id="textSlides">
          <div className="content-pad">
            <h1>Ideas for <span className="care">Care</span></h1>
            <p>
              La Fondazione Silene Ideas for CARE sostiene l'innovazione attraverso la realizzazione di nuovi modelli
              di assistenza e cura che si integrano con attività e progetti medico assistenziali a supporto
              di aree geografiche di differente complessità economico-sociale. La mission della Fondazione consiste
              nella programmazione di attività che, integrando gli aspetti educazionale e assistenziale,
              favoriscano la realizzazione di progetti duraturi e integrati nel territorio. Silene incardina le sue idee
              e i suoi progetti su quattro pilastri: <b>Collaborazione, Assistenza, Ricerca e Educazione</b>.
            </p>
          </div>
          <div className="content-pad">
            <span className="care">cooperation</span>
            <h3>collaborazione</h3>
            <p>
              <span className="uppercase">attività, competenze, networking</span><br/>
              La Fondazione Silene Ideas for CARE promuove collaborazioni a sostegno della salute attraverso la
              creazione di network internazionali. Utilizza tutte le forme di comunicazione, compresa quella artistica,
              a supporto della ricerca.
            </p>
          </div>
          <div className="content-pad">
            <span className="care">assistance</span>
            <h3>assistenza</h3>
            <p>
              <span className="uppercase">aiuto, sostegno, ambiente</span><br/>
              Tra gli obiettivi principali la Fondazione ha quello di portare aiuto in vari ambiti, da quello
              medico-scientifico a quello assistenziale, da quello informativo a quello formativo, fino alla
              realizzazione di progetti che sostengano il benessere psicofisico della persona.
            </p>
          </div>
          <div className="content-pad">
            <span className="care">research</span>
            <h3>ricerca</h3>
            <p>
              <span className="uppercase">studio, cura, progresso</span><br/>
              La Fondazione vuole attivare programmi di ricerca a supporto della cura e dell'assistenza sia in realtà
              ad elevato tenore socio economico che in aree meno privilegiate
            </p>
          </div>
          <div className="content-pad">
            <span className="care">education</span>
            <h3>educazione</h3>
            <p>
              <span className="uppercase">istruzione, formazione, inclusione, disseminazione</span><br/>
              L'educazione e l'istruzione sono parte fondamentale del progetto della Fondazione che pertanto finanzierà
              l'attività di giovani ricercatori e sosterrà la formazione nel campo sanitario. La Commissione Scientifica
              di Silene individuerà e promuoverà collaborazioni per il superamento di barriere e frontiere puntando alla
              diffusione della conoscenza e della ricerca.
            </p>
          </div>
          <div className="content-pad">
            <h1>Ideas for <span className="care">Care</span></h1>
            <p>
              La Fondazione Silene Ideas for CARE sostiene l'innovazione attraverso la realizzazione di nuovi modelli
              di assistenza e cura che si integrano con attività e progetti medico assistenziali a supporto
              di aree geografiche di differente complessità economico-sociale. La mission della Fondazione consiste
              nella programmazione di attività che, integrando gli aspetti educazionale e assistenziale,
              favoriscano la realizzazione di progetti duraturi e integrati nel territorio. Silene incardina le sue idee
              e i suoi progetti su quattro pilastri: <b>Collaborazione, Assistenza, Ricerca e Educazione</b>.
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Animation
