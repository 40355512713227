import React from "react"
import { graphql } from "gatsby"

import Header from "../components/header"
import Animation from "../components/animation"
import NewsFlex from "../components/news-flex"
import Footer from "../components/footer"

/*import fondazione from "../images/fondazione.jpg"
import progetti from "../images/progetti.jpg"
import sostieni from "../images/sostieni.jpg"*/
import arrowPurple from "../images/arrow-right-purple.svg"
import arrowPink from "../images/arrow-right-pink.svg"

const IndexPage = ({ data }) => {
  const blocks = data.allStrapiHomeBlock.nodes.sort((a, b) => a.Position - b.Position);

  return <div>
    <Header/>
    <Animation/>

    {blocks.map((b, i) =>
      <div className={i%2 ? "columns2 reverse" : "columns2 white-bg"}>
        <div className="image-col">
          <img src={b.Image.localFile.url}/>
        </div>
        <div className="text-col">
          <h2>{b.Title}</h2>
          <p>{b.Text}</p>
          <a href={b.Link} className="read-more discover-more">
            Scopri di più <img src={i%2 ? arrowPink : arrowPurple}/>
          </a>
        </div>
      </div>
    )}

    <NewsFlex/>

    <Footer/>
  </div>
}

export default IndexPage

export const pageQuery = graphql`
  query {
    allStrapiHomeBlock {
      nodes {
        Title
        Text
        Image { localFile { url } }
        Link
        Position
      }
    }
  }
`
